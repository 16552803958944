import { Injectable } from '@angular/core';
import { uniqBy } from 'lodash';
import { ITagsInput } from '../interfaces';

@Injectable()
export class TagsService {
  removeEmptyValues(tagsInput: Array<ITagsInput>): Array<ITagsInput> {
    return tagsInput.filter((tag) => {
      // CSV import returns array
      // TagsInput returns an object
      const tagName = Array.isArray(tag) ? tag[0] : tag.name;

      // trim and remove empty tags value.
      return tagName.trim() !== '';
  });
  }

  getNames(tagsInput: Array<ITagsInput>): Array<string> {
    return tagsInput.map((tag: ITagsInput) => {
      return tag.name.trim();
    });
  }

  getTags(tagsInput: Array<ITagsInput>): Array<ITagsInput> {
    return tagsInput.map((tag: ITagsInput) => {
      // CSV import returns array
      // TagsInput returns an object
      const tagName = Array.isArray(tag) ? tag[0] : tag.name;

      return {
        name: tagName.trim(),
        selected: true,
      };
    });
  }

  filterAndGetTags(tagsInput: Array<ITagsInput>): Array<ITagsInput> {
    const filteredTags = this.removeEmptyValues(tagsInput);
    const tags = this.getTags(filteredTags);

    return tags;
  }

  filterAndGetNames(tagsInput: Array<ITagsInput>): Array<string> {
    const filteredTags = this.removeEmptyValues(tagsInput);
    const tagNames = this.getNames(filteredTags);

    return tagNames;
  }

  filterAndAppendDomains(tagsInput: Array<ITagsInput>): Array<string> {
    const filteredTags = this.removeEmptyValues(tagsInput);
    const tagNames: Array<string> = [];
    const prefix = 'www.';
    filteredTags.forEach((tag: ITagsInput) => {
      const tagName = tag.name.toLowerCase();

      /**
       * for tags that are prepend with www,
       * check against tagNames array, and push domain
       */
      if (!tagNames.includes(tagName)) {
        tagNames.push(tagName);
      }

      /**
       * for tags that are not prepend with www,
       * check against tagsNames array, and push prepended domain
       */
      if (!tagName.includes(prefix) && !tagNames.includes(`${prefix}${tagName}`)) {
        tagNames.push(`${prefix}${tagName}`);
      }

      /**
       * for tags that are prepend with www,
       * push domain without www
       */
      if (tagName.includes(prefix) && !tagNames.includes(tagName.substring(4))) {
        tagNames.push(tagName.substring(4));
      }
    });

    return tagNames;
  }

  hasDuplicateIds(tags: Array<ITagsInput>): boolean {
    const uniqueTags = uniqBy(tags, 'name');
    return tags.length > uniqueTags.length;
  }

  isValid(tags: Array<ITagsInput>): boolean {
    const hasDuplicateIds = this.hasDuplicateIds(tags);
    return !hasDuplicateIds && tags.length > 0;
  }
}

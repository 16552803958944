import { IVBXLineItemSegmentCatalog } from '../interfaces';

export class VBXLineItemSegmentCatalog {
    LineItemId: string;
    Status: string;
    DeliveryStatus: string;
    SegmentUUID: string;

    public static fromJSON(params: IVBXLineItemSegmentCatalog): VBXLineItemSegmentCatalog {
        return Object.assign(new VBXLineItemSegmentCatalog(), params);
    }
}


export class VBXLineItemsSegmentCatalog {
    LineItems: VBXLineItemSegmentCatalog[];
    Total: number;

    constructor(lineItems: IVBXLineItemSegmentCatalog[]) {
        this.LineItems = lineItems.map(row => VBXLineItemSegmentCatalog.fromJSON(row));
        this.Total = lineItems.length;
    }
}

export class VBXStatusSegmentCatalog {
    LineItems: VBXLineItemSegmentCatalog[];
    Total: number;
    Status: string;
}

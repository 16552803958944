import {Injectable} from '@angular/core';
import { OktaHttpReqService } from './okta-http-req.service';

@Injectable()
export class FaultySegmentService {
    private endpoint = 'api/v1/faulty_segments';

    constructor(private oktaHttp: OktaHttpReqService) {}

    getFaultySegments(params) {
        return this.oktaHttp.request('get', this.endpoint, {params});
    }
}

import { Injectable } from '@angular/core';
import { Population } from '../models';
import { OktaHttpReqService } from './okta-http-req.service';

@Injectable()
export class PopulationsService {
    private endpoint = 'api/v1/populations';

    constructor(private oktaHttp: OktaHttpReqService) {}

    getAllPopulations() {
        return this.oktaHttp.request('get', this.endpoint);
    }

    update(params: Population) {
        return this.oktaHttp.request('put', `${this.endpoint}/${params.ID}`, params);
    }

    stage(segmentId: number, populationId: number) {
        const endpoint = `api/v1/segments/${segmentId}/populations/${populationId}/stage`;
        return this.oktaHttp.request('put', endpoint, {});
    }

    getPopulationById(segmentId: number, populationId: number) {
        const endpoint = `api/v1/segments/${segmentId}/populations/${populationId}`;
        return this.oktaHttp.request('get', endpoint);
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OktaHttpReqService } from './okta-http-req.service';

import {
    IDMDealSegmentCatalog,
    IKMLineItemSegmentCatalog,
    ISegment,
    ISegmentImpressionsByDevice,
    IVBXDealSegmentCatalog,
    IVBXLineItemSegmentCatalog,
} from '../interfaces';
import {
    DMDealsSegmentCatalog,
    KMLineItemsSegmentCatalog,
    Segment,
    SegmentImpressionsByDevice,
    VBXDealsSegmentCatalog,
    VBXLineItemsSegmentCatalog,
} from '../models';

@Injectable()
export class SegmentsService {
    private endpoint = 'api/v1/segments';

    constructor(private oktaHttp: OktaHttpReqService) {}

    getAllSegments(params) {
        // this param should be set to false
        // if set to true will exclude segment types not ready for use
        params.consumable = 'false';
        return this.oktaHttp.request('get', this.endpoint, {params});
    }

    /**
     * Get a Segment by ID from CMA Management DB
     * @param id Segment ID to be retrieved
     * @return an observable containing a Segment model
     */
    getSegment(id: number): Observable<Segment> {
        return this.oktaHttp.request<ISegment>('get', `${this.endpoint}/${id}`).pipe(
            map((segment: ISegment) => Segment.fromJSON(segment)),
        );
    }

    /**
     * Get a Segment's unique ID forecast and ID forecast from Snowflake
     * @param id Segment ID to retrieve impression data
     * @return an observable containing Segment Impression information
     */
    getImpressionsByDevice(id: number): Observable<SegmentImpressionsByDevice> {
        return this.oktaHttp.request<ISegmentImpressionsByDevice[]>('get', `${this.endpoint}/${id}/impressions-by-device`).pipe(
            map((segmentImpressions: ISegmentImpressionsByDevice[]) => new SegmentImpressionsByDevice(segmentImpressions)),
        );
    }

    /**
     * This function retrieves the load status of a segment by its ID.
     * It makes an HTTP GET request to the endpoint with the given segment ID and returns an observable string response.
     *
     * @param id - The unique identifier of the segment for which the load status is being requested.
     * @returns Observable<string> - An observable that emits the load status of the segment as a string.
     */
    getSegmentLoadStatus(id: number): Observable<string> {
        // Makes an HTTP GET request to the endpoint to fetch the load status of a specific segment by ID.
        return this.oktaHttp.request<string>('get', `${this.endpoint}/${id}/load-status`);
    }

    /**
     * Get DM Deal Segment Catalog
     * @param id Segment ID to retrieve DM Deals Segment Catalog
     * @returns an observable containing Deals Segment Catalog
     */
    getDMDealSegmentCatalog(id: number): Observable<DMDealsSegmentCatalog> {
        return this.oktaHttp.request<IDMDealSegmentCatalog[]>('get', `${this.endpoint}/${id}/dm-deals-segment-catalog`).pipe(
            map((segmentCatalog: IDMDealSegmentCatalog[]) => new DMDealsSegmentCatalog(segmentCatalog)),
        );
    }

    /**
     * Get KM Line Item Segment Catalog
     * @param id Segment ID to retrieve KM Line Items Segment Catalog
     * @returns an observable containing Line Items Segment Catalog
     */
    getKMLineItemSegmentCatalog(id: number): Observable<KMLineItemsSegmentCatalog> {
        return this.oktaHttp.request<IKMLineItemSegmentCatalog[]>('get', `${this.endpoint}/${id}/km-line-items-segment-catalog`).pipe(
            map((segmentCatalog: IKMLineItemSegmentCatalog[]) => new KMLineItemsSegmentCatalog(segmentCatalog)),
        );
    }

    /**
     * Get VBX Line Items Segment Catalog
     * @param id Segment ID to retrieve VBX Line items Segment Catalog
     * @returns an observable containing Line items Segment Catalog
     */
    getVBXLineItemSegmentCatalog(id: number): Observable<VBXLineItemsSegmentCatalog> {
        return this.oktaHttp.request<IVBXLineItemSegmentCatalog[]>('get', `${this.endpoint}/${id}/vbx-line-items-segment-catalog`).pipe(
            map((segmentCatalog: IVBXLineItemSegmentCatalog[]) => new VBXLineItemsSegmentCatalog(segmentCatalog)),
        );
    }

    /**
     * Get VBX Line Items Segment Catalog
     * @param id Segment ID to retrieve VBX Deals Segment Catalog
     * @returns an observable containing Dealss Segment Catalog
     */
    getVBXDealSegmentCatalog(id: number): Observable<VBXDealsSegmentCatalog> {
        return this.oktaHttp.request<IVBXDealSegmentCatalog[]>('get', `${this.endpoint}/${id}/vbx-deals-segment-catalog`).pipe(
            map((segmentCatalog: IVBXDealSegmentCatalog[]) => new VBXDealsSegmentCatalog(segmentCatalog)),
        );
    }

    /**
     * Delete performs the following:
     * - Delete a Segment from Postgres
     * - Delete all Populations from Postgres
     * - Unload all Populations from Aerospike
     * @param id of the Segment to be deleted
     * @return an observable containing the deleted Segment model
     */
    deleteSegment(id: number): Observable<Segment> {
        return this.oktaHttp.request<ISegment>('delete', `${this.endpoint}/${id}`).pipe(
            map((segment: ISegment) => Segment.fromJSON(segment)),
        );
    }

    createSegment(params) {
        return this.oktaHttp.request('post', this.endpoint, params);
    }

    matchLiverampSegment(params) {
        const matchEnd = this.endpoint + '/match?liverampIDs=' + params.LiveRampIDs;
        return this.oktaHttp.request('get', matchEnd, params);
    }

    matchAdobeSegment(params) {
        const matchEnd = this.endpoint + '/match-adobe-id?adobeID=' + params.AdobeID;
        return this.oktaHttp.request('get', matchEnd, params);
    }

    updateSegment(segment) {
        return this.oktaHttp.request('put', `${this.endpoint}/${segment.ID}`, segment);
    }

    /**
     * Creates Contextual Graph 2 Citadel segment
     * @param segment Segment from CMA
     */
    createContextualGraph2CitadelSegment(segment: Segment) {
        return this.oktaHttp.request('post', `/api/v1/segments/${segment.ID}/contextual-graph-2`, {});
    }

    /**
     * Creates Audience Extension segment
     * @param segment Segment
     */
    createAudienceExtensionSegment(segment: Segment) {
        return this.oktaHttp.request('post', `/api/v1/segments/${segment.ID}/audience-extension`, {});
    }

    /**
     * Creates Household Targeting segment
     * @param segment Segment
     */
    createHouseholdTargetingSegment(segment: Segment) {
        return this.oktaHttp.request('post', `/api/v1/segments/${segment.ID}/household-targeting`, {});
    }

    /**
     * Creates Liveramp Household Targeting segment
     * @param segment Segment
     */
    createLiverampHouseholdTargetingSegment(segment: Segment) {
        return this.oktaHttp.request('post', `/api/v1/segments/${segment.ID}/liveramp-household-targeting`, {});
    }

    /**
     * Creates Liveramp RampID Targeting segment
     * @param segment Segment
     */
    createLiverampRampIDTargetingSegment(segment: Segment) {
        return this.oktaHttp.request('post', `/api/v1/segments/${segment.ID}/liveramp-ramp-targeting`, {});
    }

    /**
     * Creates Brand Liveramp Household Targeting segment
     * @param segment Segment
     */
    createBrandLiverampHouseholdTargetingSegment(segment: Segment) {
        return this.oktaHttp.request('post', `/api/v1/segments/${segment.ID}/brand-liveramp-household-targeting`, {});
    }

    /**
     * Creates Brand Liveramp RampID Targeting segment
     * @param segment Segment
     */
    createBrandLiverampRampIDTargetingSegment(segment: Segment) {
        return this.oktaHttp.request('post', `/api/v1/segments/${segment.ID}/brand-liveramp-ramp-targeting`, {});
    }

    /**
     * Gets a list of faulty segments from the database
     * @param params Pagation
     */
    getFaultySegments(params) {
        return this.oktaHttp.request('get', 'api/v1/faulty_segments', {params});
    }

    /**
     * Gets completed deal details for given segment.
     * @param id Segment ID to retrieve deal details.
     */
    getCompletedPMP(id: number) {
        return this.oktaHttp.request('get', `${this.endpoint}/${id}/pmp-retargeting`);
    }

    getCompletedVBX(id: number) {
        return this.oktaHttp.request('get', `${this.endpoint}/${id}/vbx-retargeting`);
    }

    /**
     * Activate Liveramp Segments
     * @param params Liveramp IDs
     */
    activateLiverampSegments(params) {
        return this.oktaHttp.request('post', `${this.endpoint}/liveramp-activation`, params);
    }

    /**
     * Activate Eyeota Segments
     * @param params Eyeota IDs
     */
    activateEyeotaSegments(params) {
        return this.oktaHttp.request('post', `${this.endpoint}/eyeota-activation`, params);
    }

    /**
     * Gets a list of faulty segments from the database
     */
    getEyeotaCountries() {
        return this.oktaHttp.request('get', 'api/v1/eyeota-countries', {});
    }
}

export const PopulationStates = {
    NEW: 0,
    STAGING: 1,
    STAGED: 2,
    FAILED_STAGING: 3,
    LOADING: 4,
    LOADED: 5,
    FAILED_LOADING: 6,
    UNLOADING: 7,
    UNLOADED: 8,
    FAILED_UNLOADING: 9,
    MULTIPLE_POPULATIONS: 10, // Used when a faulty segment has multiple population id attached
};

import { HttpClientModule } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';

import { PapaParseModule } from 'ngx-papaparse';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { EnvConfigService, OktaAuthGuard, OktaHttpReqService } from '../shared/services';

const envConfigService = new EnvConfigService();

const oktaConfig = {
  issuer: envConfigService.okta_issuer,
  clientId: envConfigService.okta_clientId,
  redirectUri: window.location.origin + '/login/callback',
  // Should be set true unless overriding the Auth on local (for browser compatibility)
  pkce: !envConfigService.overrideAuth,
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        // don't currently have core module, if we have
        // more dependencies in the future, break this
        // into core module
        PapaParseModule,
        OktaAuthModule,
    ],
    bootstrap: [
        AppComponent,
    ],
    schemas: [
        NO_ERRORS_SCHEMA,
    ],
    providers: [
        { provide: OKTA_CONFIG, useValue: oktaConfig },
        OktaAuthGuard,
        EnvConfigService,
        OktaHttpReqService,
    ],
})
export class AppModule { }

import 'reflect-metadata';
import 'zone.js';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { set } from 'lodash';
import { AppModule } from './app/app.module';

if (process.env.ENV === 'prod') {
  enableProdMode();
}

set(window, 'Kargo.komponents', process.env.komponentsVersion);

platformBrowserDynamic().bootstrapModule(AppModule);

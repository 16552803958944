import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OktaAuthGuard } from '../shared/services';

const routes: Routes = [
    {
        path: 'dashboard',
        loadChildren: '../dashboard/dashboard.module#DashboardModule',
        canActivate: [OktaAuthGuard],
    },
    {
        path: 'segment',
        loadChildren: './../segment/segment.module#SegmentModule',
        canActivate: [OktaAuthGuard],
    },
    {
        path: 'faulty-segments',
        loadChildren: '../dashboard/dashboard.module#DashboardModule',
        canActivate: [OktaAuthGuard],
    },
    {
        path: '**',
        redirectTo: 'dashboard',
        canActivate: [OktaAuthGuard],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true }),
    ],
    exports: [
        RouterModule,
    ],
})

export class AppRoutingModule {}

export const SegmentTypes = {
    ALL: {
        name: 'All',
        value: '',
    },
    QUERY: {
        name: 'Snowflake Query',
        value: 0,
        group: 'Query',
    },
    KBR: {
        name: 'KBR',
        value: 1,
        group: 'KBR',
    },
    LIVERAMP: {
        name: 'LiveRamp',
        value: 2,
        group: 'Liveramp',
    },
    MERKLE: {
        name: 'Merkle',
        value: 3,
        group: 'Merkle',
    },
    READER: {
        name: 'Reader',
        value: 4,
        group: 'Kargo',
    },
    RETARGETING_DEAL: {
        name: 'Deal Retargeting',
        value: 5,
        group: 'Kargo',
        groupType: 'Retargeting',
    },
    RETARGETING_ORDER: {
        name: 'Campain Retargeting',
        value: 6,
        group: 'Kargo',
        groupType: 'Retargeting',
    },
    RETARGETING_PIXEL: {
        name: 'Pixel Retargeting',
        value: 7,
        group: 'Kargo',
        groupType: 'Retargeting',
    },
    TRANSUNION: {
        name: 'Transunion',
        value: 8,
        group: 'Transunion',
    },
    ORACLE: {
        name: 'Oracle',
        value: 9,
        group: 'Oracle',
    },
    RETARGETING_TRACKER: {
        name: 'Tracker Retargeting',
        value: 10,
        group: 'Kargo',
        groupType: 'Retargeting',
    },
    AUDIENCE_EXTENSION: {
        name: 'Audience Extension',
        value: 11,
        group: 'Kargo',
    },
    RETARGETING_LINE_ITEM: {
        name: 'Line Item Retargeting',
        value: 12,
        group: 'Kargo',
        groupType: 'Retargeting',
    },
    CITADEL_RETARGETING: {
        name: 'Citadel Retargeting',
        value: 13,
        group: 'Kargo',
        groupType: 'Contextual',
    },
    HOUSEHOLD_TARGETING: {
        name: 'Household Targeting',
        value: 14,
        group: 'Kargo',
    },
    RETARGETING_DEAL_GROUP: {
        name: 'Deal Group Retargeting',
        value: 15,
        group: 'Kargo',
        groupType: 'Retargeting',
    },
    ADOBE: {
        name: 'Adobe',
        value: 16,
        group: 'Adobe',
    },
    AUTOMATED_LIVERAMP: {
        name: 'Automated Liveramp',
        value: 17,
        group: 'Liveramp',
    },
    AUTOMATED_ADOBE: {
        name: 'Automated Adobe',
        value: 18,
        group: 'Adobe',
    },
    EYEOTA: {
        name: 'Eyeota',
        value: 19,
        group: 'Eyeota',
    },
    RETARGETING_VBX_DEAL_ID: {
        name: 'VBX Deal Retargeting',
        value: 20,
        group: 'Kargo',
        groupType: 'Retargeting',
    },
    RETARGETING_VBX_LINE_ITEM: {
        name: 'VBX Line Item Retargeting',
        value: 21,
        group: 'Kargo',
        groupType: 'Retargeting',
    },
    LIVERAMP_HHT: {
        name: 'Liveramp Household Extension',
        value: 22,
        group: 'Kargo',
        groupType: 'Liveramp Household Extenion',
    },
    LIVERAMP_RAMP_ID: {
        name: 'VBX Cross-Device Extension',
        value: 23,
        group: 'Kargo',
        groupType: 'Liveramp Extenion',
    },
   BRAND_LIVERAMP_HHT: {
        name: 'Brand Liveramp Household Extension',
        value: 24,
        group: 'Kargo',
        groupType: 'Brand Liveramp Household Extenion',
    },
    BRAND_LIVERAMP_RAMP_ID: {
        name: 'Brand Cross-Device Extension',
        value: 25,
        group: 'Kargo',
        groupType: 'Brand Liveramp Extenion',
    },
    ACTIVATION_LIVERAMP_ID: {
        name: 'Liveramp',
        value: 26,
        group: 'Liveramp',
    },
    ACTIVATION_EYEOTA_ID: {
        name: 'Eyeota',
        value: 27,
        group: 'Eyeota',
    },
};

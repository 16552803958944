import { IKMLineItemSegmentCatalog } from '../interfaces';

export class LineItemSegmentCatalog {
    LineItemAlphaId: string;
    LineItemId: number;
    LineItemStatus: string;
    SegmentUUID: string;

    public static fromJSON(params: IKMLineItemSegmentCatalog): LineItemSegmentCatalog {
        return Object.assign(new LineItemSegmentCatalog(), params);
    }
}

export class KMLineItemsSegmentCatalog {
    LineItems: LineItemSegmentCatalog[];
    Total: number;

    constructor(lineItems: IKMLineItemSegmentCatalog[]) {
        this.LineItems = lineItems.map(row => LineItemSegmentCatalog.fromJSON(row));
        this.Total = lineItems.length;
    }
}

export class KMStatusSegmentCatalog {
    LineItems: LineItemSegmentCatalog[];
    Total: number;
    Status: string;
}

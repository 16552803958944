export const SegmentActions = {
    DELETE: 'delete',
    CONTEXTUAL_GRAPH: 'contextual-graph',
    AUDIENCE_EXTENSION: 'audience-extension',
    HOUSEHOLD_TARGETING: 'household-targeting',
    LIVERAMP_HHT_TARGETING: 'liveramp-household-targeting',
    LIVERAMP_RAMP_ID_TARGETING: 'liveramp-ramp-targeting',
    BRAND_LIVERAMP_HHT_TARGETING: 'brand-liveramp-household-targeting',
    BRAND_LIVERAMP_RAMP_ID_TARGETING: 'brand-liveramp-ramp-targeting',
};

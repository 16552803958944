export const FilterOptions = {
    TYPE: [
        {
            name: 'Query',
            value: 0,
        },
        {
            name: 'KBR',
            value: 1,
        },
        {
            name: 'LiveRamp',
            value: 2,
        },
        {
            name: 'Merkle',
            value: 3,
        },
        {
            name: 'Reader',
            value: 4,
        },
        {
            name: 'Deal Retargeting',
            value: 5,
        },
        {
            name: 'Order Retargeting',
            value: 6,
        },
        {
            name: 'Pixel Retargeting',
            value: 7,
        },
        {
            name: 'Transunion',
            value: 8,
        },
        {
            name: 'Oracle',
            value: 9,
        },
        {
            name: 'Tracker Retargeting',
            value: 10,
        },
        {
            name: 'Audience Extensions',
            value: 11,
        },
        {
            name: 'Line Item Retargeting',
            value: 12,
        },
        {
            name: 'Citadel Retargeting',
            value: 13,
        },
        {
            name: 'Household Extension',
            value: 14,
        },
        {
            name: 'Deal Group Retargeting',
            value: 15,
        },
        {
            name: 'Adobe',
            value: 16,
        },
        {
            name: 'Automated LiveRamp',
            value: 17,
        },
        {
            name: 'Automated Adobe',
            value: 18,
        },
        {
            name: 'Eyeota',
            value: 19,
        },
        {
            name: 'VBX Deal Retargeting',
            value: 20,
        },
        {
            name: 'VBX Line Item Retargeting',
            value: 21,
        },
        {
            name: 'VBX Household Extension',
            value: 22,
        },
        {
            name: 'VBX Cross-Device Extension',
            value: 23,
        },
        {
            name: 'Brand Household Extension',
            value: 24,
        },
        {
            name: 'Brand Cross-Device Extension',
            value: 25,
        },
    ],
    ISDEPRECIATED: [
        {
            name: 'Only',
            value: 0,
            checked: 0,
        },
        {
            name: 'Exclude',
            value: 1,
            checked: 0,
        },
    ],
    ISFIRSTPARTY: [
        {
            name: 'First Party',
            value: 0,
        },
        {
            name: 'Third Party',
            value: 1,
        },
    ],
    AUTOUPDATE: [
        {
            name: 'On',
            value: 0,
        },
        {
            name: 'Off',
            value: 1,
        },
    ],
    ALWAYSON: [
        {
            name: 'Yes',
            value: 0,
        },
        {
            name: 'No',
            value: 1,
        },
    ],
    ISACTIVEFILTER: [
        {
            name: 'Active',
            value: 0,
        },
        {
            name: 'Deactived',
            value: 1,
        },
    ],
    SHOWARCHIVED: [
        {
            name: 'Include Archived',
            value: 0,
            checked: 0,
        },
        {
            name: 'Only Archived',
            value: 1,
            checked: 0,
        },
    ],
};

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { AuthRequiredFunction } from '@okta/okta-angular/src/okta/models/okta.config';

import { EnvConfigService } from './index';

@Injectable()
export class OktaAuthGuard implements CanActivate {
  constructor(private oktaAuth: OktaAuthService, private router: Router, private envConfigService: EnvConfigService) { }

  /**
   * Gateway for protected route. Returns true if there is a valid accessToken,
   * otherwise it will cache the route and start the login flow.
   * @param route Route gateway
   * @param state State management
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Check if overriding authentication
    if (this.envConfigService.overrideAuth) {
      return true;
    }
    if (await this.oktaAuth.isAuthenticated()) {
      const token = await this.oktaAuth.getAccessToken();
      if (!token) {
        this.oktaAuth.logout().then(() => {
          window.location.reload();
        });
      }
      return true;
    }

    /**
     * Get the operation to perform on failed authentication from
     * either the global config or route data injection.
     */
    const key = 'onAuthRequired';
    const onAuthRequired: AuthRequiredFunction = route.data[key] || this.oktaAuth.getOktaConfig().onAuthRequired;

    /**
     * Store the current path
     */
    // this.oktaAuth.setFromUri(state.url);
    sessionStorage.setItem('url', window.location.href);
    if (onAuthRequired) {
      onAuthRequired(this.oktaAuth, this.router);
    } else {
      this.oktaAuth.loginRedirect();
    }

    return false;
  }
}

export class Population {
    ID: number;
    IDCount: string;
    Name: string;
    SourceType: number;
    Source: string;
    State: number;
    LoadingError: string;
    LoadingState: number;
    ErrorMessage: string;
    LoadedIDCount: number;
    TotalIDCount: number;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string;

    public static isValid(population) {
        return (
            !!population.Name &&
            population.Name.length > 0
        ) && (
            !!population.Source &&
            population.Source.length > 0
        );
    }
}

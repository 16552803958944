import { isNil } from 'lodash';
import * as moment from 'moment';
import { SegmentTypes } from '../constants';
import { ISegment, ISegmentImpressionsByDevice, ISegmentType } from '../interfaces';
import { Population } from './population';

export class Segment {
    Domains: string[];
    GrapeshotSegments: string[];
    CitadelUUIDs: string[];
    ID: number;
    IDCount: number;
    Liverampids: string[];
    MerkleIds: string[];
    AdobeID: string[];
    Notes: string;
    Populations: Population[];
    PopulationCount: number;
    PrimaryRetargetingTags: string[];
    SecondaryRetargetingTags: string[];
    SegmentType: ISegmentType;
    UUID: string;
    AudienceExtension: ISegmentExtension;
    HouseholdTargeting: ISegmentExtension;
    CitadelUUID: string;
    Cohort2UUID: string;
    TransunionID: string;
    IsCategory: boolean;
    IsActive: boolean;
    CTVEnhance: boolean;
    BrandLRHHTRamp: ISegmentExtension;
    BrandLRRamp: ISegmentExtension;

    // time details
    CreatedAt: string;
    CreatedBy: number;
    DeletedAt: string;
    UpdatedAt: string;
    CompletedStartDate: Date;
    CompletedEndDate: Date;

    // values that has defaults
    AutoUpdate: boolean = true;
    EndDate: Date = moment().add(1, 'month').toDate();
    IsAdditive: boolean = true;
    IsFullReplace: boolean = false;
    IsFirstParty: boolean = true;
    IsDepreciated: boolean = false;
    Name: string = '';
    Type: number = SegmentTypes.QUERY.value;
    ConversionsOnly: boolean = false;

    // Deal Re-targeting
    Completed: boolean = false;
    DealID: string;
    VBXDealId: string;
    VBXDealLineItem: string;

    // Liveramp Extensions
    LiverampHHT: ISegmentExtension;
    LiverampRampID: ISegmentExtension;

    /**
     * Attach all incoming values into a new Segment model
     */
    public static fromJSON(params: ISegment): Segment {
        const segment = Object.assign(new Segment(), params);
        segment.PopulationCount = !isNil(segment.Populations) ? segment.Populations.length : 0;
        segment.SegmentType = Object.values(SegmentTypes).find((type) => type.value === segment.Type);
        return segment;
    }

    /**
     * Clear out optional model values
     */
    clearSegmentModel() {
        this.GrapeshotSegments = [];
        this.CitadelUUIDs = [];
        this.Domains = [];
        this.MerkleIds = [];
        this.Liverampids = [];
        this.TransunionID = '';
        this.IsCategory = false;
        this.ConversionsOnly = false;
        this.AdobeID = [];
    }

    /**
     * canEdit
     * @param segment Object
     * @returns boolean
     */
    public static canEdit(segment): boolean {
        return !Segment.isAutomatedCreation(segment) && !Segment.isKBR(segment) && !Segment.isAnExtension(segment);
    }

    public static canDelete(segment): boolean {
        // Ensure that the segment is not already deleted and is not of a restricted type
        return !segment.DeletedAt && !Segment.isAutomatedCreation(segment);
    }

    /**
     * isAutomatedCreation
     * @param segment Object
     * @returns boolean
     */
    public static isAutomatedCreation(segment): boolean {
        // Internal Extensions
        return segment.Type === SegmentTypes.EYEOTA.value ||
               segment.Type === SegmentTypes.AUTOMATED_LIVERAMP.value ||
               segment.Type === SegmentTypes.AUTOMATED_ADOBE.value ||
               segment.Type === SegmentTypes.TRANSUNION.value ||
               Segment.isAnExtension(segment); // Additional check for extensions
    }

    /**
     * Checks if segment type is An Extension
     * @param segment Object
     * @returns boolean
     */
    public static isAnExtension(segment): boolean {
        // Internal Extensions
        return segment.Type === SegmentTypes.AUDIENCE_EXTENSION.value ||
        segment.Type === SegmentTypes.HOUSEHOLD_TARGETING.value ||
        // Liveramp Extensions
        // For VBX
        segment.Type === SegmentTypes.LIVERAMP_HHT.value ||
        segment.Type === SegmentTypes.LIVERAMP_RAMP_ID.value ||
        // For Brand
        segment.Type === SegmentTypes.BRAND_LIVERAMP_HHT.value ||
        segment.Type === SegmentTypes.BRAND_LIVERAMP_RAMP_ID.value;
    }

    /**
     * Checks if segment type is Audience Extension
     * @param segment Object
     * @returns boolean
     */
    public static isHouseholdTargeting(segment): boolean {
        return segment.Type === SegmentTypes.HOUSEHOLD_TARGETING.value;
    }

    public static isTerminatedType(segment): boolean {
        return segment.Type === SegmentTypes.LIVERAMP.value;
    }

    /**
     * Checks if segment type is KBR
     * @param segment Object
     * @returns boolean
     */
    public static isKBR(segment): boolean {
        return segment.Type === SegmentTypes.KBR.value;
    }

    /**
     * Checks if segment type is VBX
     * @param segment Object
     * @returns boolean
     */
    public static isVBX(segment): boolean {
        return segment.Type === SegmentTypes.RETARGETING_VBX_DEAL_ID.value || segment.Type === SegmentTypes.RETARGETING_VBX_LINE_ITEM.value;
    }

    public static hasBrandExtensions(segment): boolean {
        return segment.Type === SegmentTypes.RETARGETING_DEAL_GROUP.value ||
            segment.Type === SegmentTypes.RETARGETING_LINE_ITEM.value ||
            segment.Type === SegmentTypes.RETARGETING_DEAL.value ||
            segment.Type === SegmentTypes.RETARGETING_ORDER.value;
    }

    /**
     * Check if segment has extensions
     * @param segment Object
     * @returns boolean
     */
    public static hasExtensions(segment): boolean {
        return segment.AudienceExtension.uuid !== '' ||
        segment.HouseholdTargeting.uuid !== '' ||
        segment.CitadelUUID !== '' ||
        segment.Cohort2UUID !== '' ||
        segment.LiverampRampID.uuid !== '' ||
        segment.LiverampHHT.uuid !== '' ||
        segment.BrandLRHHTRamp.uuid !== '' ||
        segment.BrandLRRamp.uuid !== '';
    }
}

export interface ISegmentExtension {
    uuid: string;
    id:   number;
}

export class SegmentImpressionsByDevice {
    DeviceImpressions: { AvgFrequency: number, IDForecast: number, UniqueIDForecast: number, DeviceType: string }[] = [];
    TotalIDForecast: number = 0;
    TotalUniqueIDForecast: number = 0;
    TotalAvgFrequency: number = 0;

    constructor(params: ISegmentImpressionsByDevice[]) {
        for (const device of params) {
            const deviceObject: {AvgFrequency: number, IDForecast: number, UniqueIDForecast: number, DeviceType: string} = {
                DeviceType: device.DeviceType,
                IDForecast: device.IDForecast,
                UniqueIDForecast: device.UniqueIDForecast,
                AvgFrequency: this.getAverageFrequency(device.UniqueIDForecast, device.IDForecast),
            };
            // Sum up the totals
            this.TotalIDForecast += device.IDForecast;
            this.TotalUniqueIDForecast += device.UniqueIDForecast;

            this.DeviceImpressions.push(deviceObject);
        }

        // Calculate the total average frequency for all device types
        this.TotalAvgFrequency = this.getAverageFrequency(this.TotalUniqueIDForecast, this.TotalIDForecast);
    }

    /**
     * Calculate average frequency of bid request.
     */
    getAverageFrequency(UniqueIDForecast: number, IDForecast: number): number {
        return UniqueIDForecast > 0 ? IDForecast / UniqueIDForecast : 0;
    }
}

import { IDMDealSegmentCatalog } from '../interfaces';

export class DealSegmentCatalog {
    DealDealID: string;
    DealID: number;
    DealStatus: string;
    SegmentUUID: string;

    public static fromJSON(params: IDMDealSegmentCatalog): DealSegmentCatalog {
        return Object.assign(new DealSegmentCatalog(), params);
    }
}

export class DMDealsSegmentCatalog {
    Deals: DealSegmentCatalog[];
    Total: number;

    constructor(deals: IDMDealSegmentCatalog[]) {
        this.Deals = deals.map(row => DealSegmentCatalog.fromJSON(row));
        this.Total = deals.length;
    }
}

export class DMStatusSegmentCatalog {
    Deals: DealSegmentCatalog[];
    Total: number;
    Status: string;
}

import { IUser } from '../interfaces';

export class User {
    company_id: number;
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    full_name: string;

    public static fromJSON(params: IUser) {
        const user = Object.assign(new User(), params);
        user.full_name = `${user.first_name} ${user.last_name}`;

        return user;
    }
}

import { Injectable } from '@angular/core';

@Injectable()

export class WorkflowEndpoints {

    private env_options = {
        DEVELOPMENT: 'krg.io',
        DEV: 'dev.kargo.com',
        STAGING: 'staging.kargo.com',
    };

    private applicationHosts = {
        DEAL_MANAGER: {
            DEVELOPMENT: 'http://deal-manager.krg.io',
            DEV: 'https://deal-manager.dev.kargo.com',
            STAGING: 'https://deal-manager.dev.kargo.com',
            PROD: 'https://deal-manager.prod.kargo.com',
        },
        MARKETPLACE: {
            DEVELOPMENT: 'http://marketplace.krg.io',
            DEV: 'https://marketplace.dev.kargo.com',
            STAGING: 'https://marketplace.staging.kargo.com',
            PROD: 'https://marketplace.kargo.com',
        },
    };

    private km_line_items_path = 'advertising#/line-items';
    private dm_deals_path = 'deal-dashboard/deals';

    /**
     * Functions are used to return urls of work-flow websites ,
     * Sites: currently limited to "MarketPlace" or "DealManager" sites
     */

    getKMPath() {
        const host = this.applicationHosts.MARKETPLACE[this.getCurrentEnv()];
        return `${host}/${this.km_line_items_path}`;
    }

    getDMPath() {
        const host = this.applicationHosts.DEAL_MANAGER[this.getCurrentEnv()];
        return `${host}/${this.dm_deals_path}`;
    }

    getCurrentEnv() {
        const currentHost = window.location.host;
        for (const [key, value] of Object.entries(this.env_options)) {
            if (currentHost.includes(value)) {
                return key;
            }
        }
        return 'PROD';
    }
}

import { Injectable } from '@angular/core';

@Injectable()
export class EnvConfigService {

  public env: string;
  public okta_issuer: string;
  public okta_clientId: string;
  public overrideAuth: boolean = false;
  constructor () {
    this.getConfig();
  }

  getConfig() {
    const hostname = window.location.hostname;
    if (hostname.includes('krg.io')) {
      this.env = 'development';
      this.okta_issuer = 'https://login-dev.kargo.com/oauth2/default';
      this.okta_clientId = '0oa603jqijrALs7Td1d7';
      // set to true to override okta on client side
      this.overrideAuth = false;
    } else if (hostname.includes('dev.kargo.com')) {
      this.env = 'dev';
      this.okta_issuer = 'https://login-dev.kargo.com/oauth2/default';
      this.okta_clientId = '0oa603jqijrALs7Td1d7';
    } else if (hostname.includes('staging.kargo.com')) {
      this.env = 'staging';
      this.okta_issuer = 'https://login-dev.kargo.com/oauth2/default';
      this.okta_clientId = '0oa603jqijrALs7Td1d7';
    } else {
      this.env = 'prod';
      this.okta_issuer = 'https://login.kargo.com/oauth2/default';
      this.okta_clientId = '0oa9uv0yfvqPjFo5H4x7';
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { OktaHttpReqService } from './okta-http-req.service';

import { IUser } from '../interfaces' ;
import { User} from '../models';

@Injectable()
export class UsersService {
    private endpoint = 'api/v1/users';

    constructor(private oktaHttp: OktaHttpReqService) {}

    /**
     * Get a user by ID from KUser
     * @param id User ID to search for in KUser
     * @return an observable containing a User Model
     */
    getUserInfo(id: number): Observable<User> {
        // return an empty User model if incoming ID value is 0
        if (!id) {
            return Observable.of(new User());
        } else {
            return this.oktaHttp.request<IUser>('get', `${this.endpoint}/${id}`).pipe(
                map((user: IUser) => User.fromJSON(user)),
            );
        }
    }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';

import { from } from 'rxjs/observable/from';
import { switchMap } from 'rxjs/operators';

interface IOptions {
  body?: any;
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  responseType?: 'json';
  params?: HttpParams | {
      [param: string]: string | string[];
  };
}

@Injectable()
export class OktaHttpReqService {
  constructor (
    private http: HttpClient,
    private oktaAuth: OktaAuthService,
  ) {}

  request<T>(method: string, url: string, data?: any, responseType?: any) {
    const getToken$ = from(this.oktaAuth.getAccessToken());

    return getToken$.pipe(
      switchMap(token => {
      const options: IOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        observe: 'body',
        responseType: responseType || 'json',
      };

      if (data && (method === 'post' || method === 'put')) {
        options.body = data;
      }
      if (data && data.params && (method === 'get' || method === 'delete')) {
        options.params = data.params;
      }

      return this.http.request<T>(method, url, options);
    }));

  }
}

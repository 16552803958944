
import { IVBXDealSegmentCatalog } from '../interfaces';

export class VBXDealSegmentCatalog {
    DealId: string;
    Status: string;
    DealName: string;
    SegmentUUID: string;

    public static fromJSON(params: IVBXDealSegmentCatalog): VBXDealSegmentCatalog {
        return Object.assign(new VBXDealSegmentCatalog(), params);
    }
}


export class VBXDealsSegmentCatalog {
    Deals: VBXDealSegmentCatalog[];
    Total: number;

    constructor(deals: IVBXDealSegmentCatalog[]) {
        this.Deals = deals.map(row => VBXDealSegmentCatalog.fromJSON(row));
        this.Total = deals.length;
    }
}

export class VBXDealsStatusSegmentCatalog {
    Deals: VBXDealSegmentCatalog[];
    Total: number;
    Status: string;
}

import { Component, ViewContainerRef, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'cma-ui',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

export class AppComponent {
    constructor (
        public viewContainerRef: ViewContainerRef,
    ) {
        this.viewContainerRef = viewContainerRef;
    }
}
